import { createSelector } from 'reselect';
import {
  routes,
  ISiteDetailsTankRoute,
  INote,
  ISiteChannel,
  ISiteDetailsTank,
  ISiteTankChannel,
  IUnit,
  IProduct,
  IController,
  ISiteDetailsControllerRoute,
  ISiteDetailsAlarmRoute,
  IAlarm
} from '../../interfaces';
import {
  IControllerReducer,
  rootState,
  IChannelReducerState,
  INoteReducerState,
  isDisplayNote
} from '../../reducers';
import {
  isNone,
  ensureDate,
  getAsyncEntity,
  asyncEntityIsFetched,
  getEntity,
  getEntityOrDefault,
  getAsyncEntitiesByAsyncArray,
  channelIsTankChannel,
  getEntityOrUndefined,
  mapFetchedAsyncEntity,
  emptyArray,
  orderByPredicate
} from '../../utility';
import { routeNames } from '../../config';
import { Maybe } from 'tsmonad';
import { getSiteIdParameter, selectProducts } from '../common';
import { IAsyncDictionary, IAsyncEntity } from '../../types/index';
import { ITrendData, ITrendDataAndStats } from '../../models/trend';
import { IChannelHistoryReducerState } from '../../reducers/entity/channelHistoryReducer';

const routeIsSiteDetailsAlarm = (
  route: routes
): route is ISiteDetailsAlarmRoute =>
  route.route === routeNames.sitedetailsAlarms;

const getSiteDetailsAlarmDetailsFromRoute = (
  route: routes
): Maybe<ISiteDetailsAlarmRoute> =>
  routeIsSiteDetailsAlarm(route)
    ? Maybe.just(route)
    : route.child
    ? getSiteDetailsAlarmDetailsFromRoute(route.child)
    : Maybe.nothing();

export const getSiteDetailsAlarmRouteFromState = createSelector<
  rootState,
  routes,
  Maybe<ISiteDetailsAlarmRoute>
>(
  state => state.router.currentRoute,
  getSiteDetailsAlarmDetailsFromRoute
);

export const getAlarmIdFromRoute = createSelector<
  rootState,
  Maybe<ISiteDetailsAlarmRoute>,
  Maybe<number>
>(
  getSiteDetailsAlarmRouteFromState,
  mayberoute =>
    mayberoute.bind(route =>
      route.params.alarmId
        ? Maybe.just(parseInt(route.params.alarmId, 10))
        : Maybe.nothing<number>()
    )
);

export const getEditAlarmIdFromRoute = createSelector<
  rootState,
  routes,
  number
>(
  state => state.router.currentRoute,
  route =>
    route.child && route.child.params.alarmId
      ? parseInt(route.child.params.alarmId, 10)
      : 0
);

const routeIsSiteDetailsController = (
  route: routes
): route is ISiteDetailsControllerRoute =>
  route.route === routeNames.sitedetailsControllers;

const getSiteDetailsControllerDetailsFromRoute = (
  route: routes
): Maybe<ISiteDetailsControllerRoute> =>
  routeIsSiteDetailsController(route)
    ? Maybe.just(route)
    : route.child
    ? getSiteDetailsControllerDetailsFromRoute(route.child)
    : Maybe.nothing();

export const getSiteDetailsControllerRouteFromState = createSelector<
  rootState,
  routes,
  Maybe<ISiteDetailsControllerRoute>
>(
  state => state.router.currentRoute,
  getSiteDetailsControllerDetailsFromRoute
);

export const getControllerIdFromRoute = createSelector<
  rootState,
  Maybe<ISiteDetailsControllerRoute>,
  Maybe<number>
>(
  getSiteDetailsControllerRouteFromState,
  mayberoute =>
    mayberoute.bind(route =>
      route.params.controllerId
        ? Maybe.just(parseInt(route.params.controllerId, 10))
        : Maybe.nothing<number>()
    )
);

const routeIsSiteDetailsTank = (
  route: routes
): route is ISiteDetailsTankRoute =>
  route.route === routeNames.sitedetailsTanksDetails;

const getSiteDetailsTankDetailsFromRoute = (
  route: routes
): Maybe<ISiteDetailsTankRoute> =>
  routeIsSiteDetailsTank(route)
    ? Maybe.just(route)
    : route.child
    ? getSiteDetailsTankDetailsFromRoute(route.child)
    : Maybe.nothing();

export const getSiteDetailsTankRouteFromState = createSelector<
  rootState,
  routes,
  Maybe<ISiteDetailsTankRoute>
>(
  state => state.router.currentRoute,
  getSiteDetailsTankDetailsFromRoute
);

export const getTankDetailsActiveTab = createSelector<
  rootState,
  Maybe<ISiteDetailsTankRoute>,
  Maybe<string>
>(
  getSiteDetailsTankRouteFromState,
  mayberoute =>
    mayberoute.bind(route =>
      route.params.tab ? Maybe.just(route.params.tab) : Maybe.nothing<string>()
    )
);

export const selectChannelsForCurrentSite = createSelector<
  rootState,
  Maybe<number>,
  IChannelReducerState,
  ISiteChannel[]
>(
  getSiteIdParameter,
  state => state.channels,
  (siteId, channels) => {
    return getEntityOrDefault(
      getAsyncEntitiesByAsyncArray(
        getAsyncEntity(channels.siteChannelsForSites, siteId.valueOr(0)),
        channels.byId
      ),
      emptyArray
    );
  }
);

export const selectTanksForCurrentSite = createSelector<
  rootState,
  Maybe<number>,
  IChannelReducerState,
  ISiteChannel[]
>(
  getSiteIdParameter,
  state => state.channels,
  (siteId, channels) => {
    return getEntityOrDefault(
      getAsyncEntitiesByAsyncArray(
        getAsyncEntity(channels.tanksForSites, siteId.valueOr(0)),
        channels.byId
      ),
      emptyArray
    );
  }
);

export const createSiteDetailsTank = (
  channel: ISiteTankChannel,
  products: IProduct[],
  alarms: IAlarm[],
  tankHistory: ITrendData | undefined
): ISiteDetailsTank => ({
  alias: channel.alias,
  lastSample: channel.lastSample,
  lastSampleTime: ensureDate(channel.lastSampleTime),
  percentFull: channel.tankDetails.percentage,
  productId: channel.productId,
  reachMinimum: channel.tankDetails.reachMinimum
    ? ensureDate(channel.tankDetails.reachMinimum)
    : undefined,
  reachEmpty: channel.tankDetails.reachEmpty
    ? ensureDate(channel.tankDetails.reachEmpty)
    : undefined,
  hasAlarm: alarms.some(
    a => a.siteChannelId === channel.siteChannelId && a.active
  ),
  tankId: channel.tankDetails.tankId,
  minimumDateHasBeenReached: channel.tankDetails.reachMinimum
    ? ensureDate(channel.tankDetails.reachMinimum) < new Date()
    : false,
  lastFilled: channel.tankDetails.lastFilled
    ? ensureDate(channel.tankDetails.lastFilled)
    : undefined,
  capacity: channel.capacity,
  siteChannelId: channel.siteChannelId,
  product: channel.productId
    ? products.find(p => channel.productId === p.productId)
    : undefined,
  tankHistory,
  isParked: channel.isParked
});

export const createSiteDetailsTankFromHistoryList = (
  channel: ISiteTankChannel,
  products: IProduct[],
  alarms: IAlarm[],
  tankHistory: ITrendDataAndStats[] | undefined
): ISiteDetailsTank => {
  const historyForChannel = tankHistory
    ? tankHistory.find(h => h.siteChannelId === channel.siteChannelId)
    : undefined;
  return createSiteDetailsTank(
    channel,
    products,
    alarms,
    historyForChannel ? historyForChannel.trendData : undefined
  );
};

export const selectSiteDetailsTanksForCurrentSite = createSelector<
  rootState,
  ISiteChannel[],
  IAsyncEntity<IProduct[]>,
  IChannelHistoryReducerState,
  ISiteDetailsTank[]
>(
  selectTanksForCurrentSite,
  selectProducts,
  state => state.channelHistory,
  (tanks, products, channelHistory) =>
    tanks
      .filter(channelIsTankChannel)
      .map(t =>
        createSiteDetailsTank(
          t,
          getEntityOrDefault(products, []),
          emptyArray,
          getEntityOrUndefined(
            mapFetchedAsyncEntity(
              getAsyncEntity(channelHistory.byId, t.siteChannelId),
              a => a.trendData
            )
          )
        )
      )
);

export const getUnitForUnitId = (
  unitId: number | undefined,
  units: IAsyncDictionary<IUnit>
) => {
  if (isNone(unitId)) return undefined;
  const asyncUnit = getAsyncEntity(units, unitId);
  return asyncEntityIsFetched(asyncUnit) ? getEntity(asyncUnit) : undefined;
};

export const controllersForSiteSelector = createSelector<
  rootState,
  Maybe<number>,
  IControllerReducer,
  IController[]
>(
  getSiteIdParameter,
  state => state.controllers,
  (siteId, controllers) => {
    return getEntityOrDefault(
      getAsyncEntitiesByAsyncArray(
        getAsyncEntity(controllers.controllersForSites, siteId.valueOr(0)),
        controllers.byId
      ),
      []
    );
  }
);

export const notesForSiteSelector = createSelector<
  rootState,
  Maybe<number>,
  INoteReducerState,
  INote[]
>(
  getSiteIdParameter,
  state => state.notes,
  (siteId, notes) =>
    orderByPredicate(
      getEntityOrDefault(
        getAsyncEntitiesByAsyncArray(
          getAsyncEntity(notes.notesForSites, siteId.valueOr(0)),
          notes.byId
        ),
        emptyArray
      ),
      note => (isDisplayNote(note) ? Number.MAX_SAFE_INTEGER : note.noteId),
      'desc'
    )
);
