import './sitedetailstankpreview.css';
import { customElement, bindable } from 'aurelia-templating';
import { BaseViewModel } from '../../../common';
import { getLogger } from 'aurelia-logging';
import {
  ChannelService,
  ProductService,
  AlarmService
} from '../../../../services';
import { IAsyncEntity } from '../../../../types';
import {
  ISiteDetailsTank,
  ISiteChannel,
  ISiteTankChannel,
  IProduct,
  IAlarm
} from '../../../../interfaces';
import { defaultMemoize } from 'reselect';
import { channelIsTankChannel } from '../../../../utility';
import { createSiteDetailsTankFromHistoryList } from '../../selectors';
import { ITrendDataAndStats } from '../../../../models';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { routeNames } from '../../../../config';

const getSiteChannelIds = defaultMemoize((channels: ISiteChannel[]) =>
  channels.map(channel => channel.siteChannelId)
);

const sitedetailTanks = defaultMemoize(
  (
    tanks: ISiteTankChannel[],
    products: IProduct[],
    alarms: IAlarm[],
    history: ITrendDataAndStats[] | undefined
  ) =>
    tanks.map(tank =>
      createSiteDetailsTankFromHistoryList(tank, products, alarms, history)
    )
);

const filterOnlyTankChannels = defaultMemoize((channels: ISiteChannel[]) =>
  channels.filter(channelIsTankChannel)
);

@autoinject()
@customElement('sitedetails-tankpreview')
export class SitedetailsTankPreview extends BaseViewModel<
  IAsyncEntity<ISiteDetailsTank[]>
> {
  @bindable() siteId: number;

  constructor(
    private channelService: ChannelService,
    private productService: ProductService,
    private alarmService: AlarmService,
    private router: Router
  ) {
    super(getLogger('sitedetails-tankpreview'));
  }

  bind() {
    this.attachMapState(this.mapState);
  }

  navigateToTank(tank: ISiteDetailsTank) {
    const currentRouteIsIndex =
      this.router.currentInstruction.config.name ===
      routeNames.sitedetailsIndex;
    const isMobile = false;
    this.router.navigate(`tanks/tankDetails/${tank.siteChannelId}`, {
      replace: !currentRouteIsIndex || !isMobile
    });
  }

  mapState = () => {
    const productsFetcher = this.productService.getAll();
    const alarmsFetcher = this.alarmService.getAlarmsBySiteId(this.siteId);
    const tankFetcher = this.channelService
      .getSiteChannelsForSite(this.siteId)
      .map(filterOnlyTankChannels);

    const tankHistory = tankFetcher
      .map(getSiteChannelIds)
      .bind(this.channelService.getChannelHistoryForSiteChannels)
      .getEntityOrUndefined();

    const t = tankFetcher
      .map3(productsFetcher, alarmsFetcher, (tanks, products, alarms) =>
        sitedetailTanks(tanks, products, alarms, tankHistory)
      )
      .getAsyncEntity();

    return t;
  };
}
