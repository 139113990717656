import { autoinject, computedFrom } from 'aurelia-framework';
import {
  Router,
  RouterConfiguration,
  NavModel,
  RouteConfig
} from 'aurelia-router';
import { PLATFORM } from 'aurelia-pal';
import { BaseViewModel } from '../common/BaseViewModel';
import { rootState } from '../../reducers';
import {
  ChannelService,
  ControllerService,
  AlarmService,
  SiteService
} from '../../services';
import { routeNames, features } from '../../config';
import {
  IController,
  routes,
  IAlarm,
  ISession,
  ISiteChannel
} from '../../interfaces';
import {
  isNone,
  routeIsActive,
  emptyArray,
  userHasFeature,
  ensureNumber,
  isEmpty,
  getInnerRoute,
  channelIsTankChannel
} from '../../utility';
import { toggleShowTanks, toggleSubMenuSiteDetails } from '../../actions';
import { getLogger } from 'aurelia-logging';
import { selectSiteDetailsTanksForCurrentSite } from './selectors';
import { AccessLevel } from '../../interfaces/enums/accesslevel';
import { getSession } from '../../config/sessionService';
import './sitedetails.css';
import { defaultMemoize } from 'reselect';
import { LocalSettings } from '../../services/localSettingsService';

interface ISiteDetailsState {
  siteId: number;
  showNavigation: boolean;
  showTanks: boolean;
  hasMultipleTanks: boolean;
  siteChannelIdsForTanks: number[];
  submenuIsShown: boolean;
  controllers: IController[];
  screenIsMobile: boolean;
  currentRoute: routes;
  session: ISession;

  accessRights: {
    alarms: boolean;
    tanks: boolean;
    notes: boolean;
    siteEquipment: boolean;
    gallery: boolean;
    trendTool: boolean;
    controller: boolean;
    channels: boolean;
  };

  alarms: {
    active: IAlarm[];
  };
}

const showTanks = (state: rootState) =>
  state.device.screenSize !== 'mobile' &&
  (state.sitedetails.showTanks ||
    (routeIsActive(state.router.currentRoute, routeNames.sitedetailsTanks) &&
      selectSiteDetailsTanksForCurrentSite(state).length > 1));

const mapAccessRights = (session: ISession) => ({
  alarms: userHasFeature(session, features.alarms, AccessLevel.Read),
  tanks: userHasFeature(session, features.siteTanks, AccessLevel.Read),
  notes: userHasFeature(session, features.allSiteNotes, AccessLevel.Read),
  siteEquipment: userHasFeature(
    session,
    features.siteEquipment,
    AccessLevel.Read
  ),
  gallery: userHasFeature(session, features.siteGallery, AccessLevel.Read),
  trendTool: userHasFeature(session, features.trendTool, AccessLevel.Read),
  channels: userHasFeature(
    session,
    features.siteDetailsChannels,
    AccessLevel.Read
  ),
  controller:
    userHasFeature(session, features.siteDetailsController, AccessLevel.Read) ||
    userHasFeature(session, features.controllerParameters, AccessLevel.Read) ||
    userHasFeature(session, features.controllerProfiles, AccessLevel.Read)
});

const showNavigationBar = ({ device, router }: rootState): boolean => {
  if (device.screenSize !== 'mobile') return true;

  if (routeIsActive(router.currentRoute, routeNames.sitedetails)) {
    if (
      routeIsActive(
        router.currentRoute,
        routeNames.sitedetailsTanksDetails,
        routeNames.sitedetailsChannelDetails
      )
    )
      return false;

    const { route, params } = getInnerRoute(router.currentRoute);
    if (route === routeNames.sitedetailsIndex && params.action === 'edit')
      return false;

    if (
      route === 'siteDetailsChannels' &&
      (params.action === 'edit' || params.action === 'create')
    )
      return false;

    if (route === 'siteDetailsAlarmsEdit') return false;

    if (route === routeNames.sitedetailsAlarms && !isNone(params.alarmId))
      return false;

    if (route === routeNames.sitedetailsAssets && !isEmpty(params.action))
      return false;

    if (
      route === 'sitedetailscontrollerdetails' &&
      !isEmpty(params.controllerId)
    ) {
      return false;
    }

    if (route === routeNames.sitedetailsMap && !isEmpty(params.action))
      return false;

    if (route === 'sitedetails-gallerydetails') return false;
  }

  return true;
};

const filterOnlyTankChannels = defaultMemoize((channels: ISiteChannel[]) =>
  channels.filter(channelIsTankChannel)
);

@autoinject()
export class SiteDetails extends BaseViewModel<ISiteDetailsState> {
  private router: Router;

  constructor(
    private channelService: ChannelService,
    private controllerService: ControllerService,
    private alarmService: AlarmService,
    private siteService: SiteService,
  ) {
    super(getLogger('SiteDetails'));
  }

  configureRouter(config: RouterConfiguration, router: Router) {
    let routes: RouteConfig[] = [
      {
        route: ['', 'details/:action', 'details'],
        name: routeNames.sitedetailsIndex,
        title: 'UI_SiteDetails_Menu_Details',
        settings: {
          icon: 'fa-industry',
          menuTitle: 'UI_SiteDetails_Menu_Details',
          desktop: 1,
          mobile: 1
        },
        moduleId: PLATFORM.moduleName(
          'pages/sitedetails/modules/sitedetailsindex/sitedetailsindex',
          'sitedetails-index'
        ),
        nav: true,
        href: 'details'
      },
      {
        route: 'map/:action?',
        name: routeNames.sitedetailsMap,
        href: 'map',
        title: 'UI_SiteDetails_Menu_Map',
        settings: {
          icon: 'fa-map-marker',
          menuTitle: 'UI_SiteDetails_Menu_Map',
          mobile: 1
        },
        moduleId: PLATFORM.moduleName(
          'pages/sitedetails/modules/sitedetailsmap/sitedetailsmap',
          'sitedetails-map'
        ),
        nav: true
      }
    ];

    const {
      alarms,
      gallery,
      siteEquipment,
      notes,
      tanks,
      trendTool,
      controller,
      channels
    } = mapAccessRights(getSession());

    const addRoute = (
      condition: boolean,
      existing: RouteConfig[],
      ...newElements: RouteConfig[]
    ) => (condition ? [...existing, ...newElements] : existing);

    routes = addRoute(notes, routes, {
      route: 'notes',
      name: 'siteDetailsNotes',
      title: 'UI_SiteDetails_Menu_Notes',
      settings: {
        icon: 'fa-sticky-note-o',
        menuTitle: 'UI_SiteDetails_Menu_Notes',
        mobile: 3
      },
      moduleId: PLATFORM.moduleName(
        'pages/sitedetails/modules/sitedetailsnotes/sitedetailsnotes',
        'sitedetails-notes'
      ),
      nav: true
    });

    routes = addRoute(trendTool, routes, {
      route: 'graph',
      name: routeNames.sitedetailsGraph,
      title: 'UI_SiteDetails_Menu_Graph',
      settings: {
        icon: 'fa-line-chart',
        menuTitle: 'UI_SiteDetails_Menu_Graph',
        desktop: 2
      },
      moduleId: PLATFORM.moduleName(
        'pages/sitedetails/modules/sitedetailsgraph/sitedetailsgraph',
        'trend-tool'
      ),
      nav: true
    });

    routes = addRoute(tanks, routes, {
      route: 'tanks',
      name: routeNames.sitedetailsTanks,
      title: 'UI_SiteDetails_Menu_Tanks',
      href: 'tanks',
      settings: {
        icon: 'fa-tasks',
        menuTitle: 'UI_SiteDetails_Menu_Tanks',
        desktop: 3
      },
      moduleId: PLATFORM.moduleName(
        'pages/sitedetails/modules/sitedetailstanks/sitedetailstanks',
        'sitedetails-tanks'
      ),
      nav: true
    });

    routes = addRoute(tanks || trendTool, routes, {
      route: 'tanks',
      name: routeNames.sitedetailsTanks,
      title: 'UI_SiteDetails_Menu_Graph',
      href: 'tanks',
      settings: {
        icon: 'fa-line-chart',
        menuTitle: 'UI_SiteDetails_Menu_Graph',
        mobile: 4
      },
      moduleId: PLATFORM.moduleName(
        'pages/sitedetails/modules/sitedetailstanks/sitedetailstanks',
        'sitedetails-tanks'
      ),
      nav: true
    });

    routes = addRoute(
      alarms,
      routes,
      {
        route: 'alarms/edit/:alarmId?',
        name: 'siteDetailsAlarmsEdit',
        title: 'UI_SiteDetails_Menu_Alarms',
        settings: { icon: 'fa-bell', menuTitle: 'UI_SiteDetails_Menu_Alarms' },
        moduleId: PLATFORM.moduleName(
          'pages/sitedetails/modules/sitedetailsalarms/modules/edit/sitedetailsalarmsedit',
          'sitedetails-alarms-edit'
        ),
        nav: false
      },
      {
        route: 'alarms/:alarmId?',
        name: routeNames.sitedetailsAlarms,
        href: 'alarms',
        title: 'UI_SiteDetails_Menu_Alarms',
        settings: {
          icon: 'fa-bell',
          menuTitle: 'UI_SiteDetails_Menu_Alarms',
          mobile: 6,
          desktop: 4
        },
        moduleId: PLATFORM.moduleName(
          'pages/sitedetails/modules/sitedetailsalarms/sitedetailsalarms',
          'sitedetails-alarms'
        ),
        nav: true
      }
    );

    routes = addRoute(controller, routes, {
      route: 'controllers',
      name: routeNames.sitedetailsControllers,
      href: 'controllers',
      title: 'UI_SiteDetails_Menu_Controllers',
      settings: {
        icon: 'fa-microchip',
        menuTitle: 'UI_SiteDetails_Menu_Controllers',
        desktop: 6,
        mobile: 8
      },
      moduleId: PLATFORM.moduleName(
        'pages/sitedetails/modules/sitedetailscontrollers/sitedetailscontrollers',
        'sitedetails-controllers'
      ),
      nav: true
    });

    routes = addRoute(channels, routes, {
      route: 'channels/:action?/:siteChannelId?/:type?',
      href: 'channels',
      name: 'siteDetailsChannels',
      title: 'UI_SiteDetails_Menu_Channels',
      settings: {
        icon: 'fa-battery-three-quarters',
        menuTitle: 'UI_SiteDetails_Menu_Channels',
        desktop: 5,
        mobile: 7
      },
      moduleId: PLATFORM.moduleName(
        'pages/sitedetails/modules/sitedetailschannels/sitedetailschannels',
        'sitedetails-channels'
      ),
      nav: true
    });

    routes = addRoute(siteEquipment, routes, {
      route: 'assets/:action?/:equipmentId?',
      name: routeNames.sitedetailsAssets,
      title: 'UI_SiteDetails_Menu_Assets',
      href: 'assets',
      settings: {
        icon: 'fa-archive',
        menuTitle: 'UI_SiteDetails_Menu_Assets',
        desktop: 7,
        mobile: 9
      },
      moduleId: PLATFORM.moduleName(
        'pages/sitedetails/modules/sitedetailsassets/sitedetailsassets',
        'sitedetails-assets'
      ),
      nav: true
    });

    routes = addRoute(gallery, routes, {
      route: 'gallery',
      name: 'siteDetailsGallery',
      title: 'UI_SiteDetails_Menu_Gallery',
      settings: {
        icon: 'fa-picture-o',
        menuTitle: 'UI_SiteDetails_Menu_Gallery',
        desktop: 8,
        mobile: 5
      },
      moduleId: PLATFORM.moduleName(
        'pages/sitedetails/modules/sitedetailsgallery/sitedetailsgallery',
        'sitedetails-gallery'
      ),
      nav: true
    });

    config.map(routes);

    this.router = router;
  }

  getHighlightCount = (row: NavModel) => {
    switch (row.config.name) {
      case routeNames.sitedetailsAlarms:
        return this.state.alarms.active.length;
      default:
        return 0;
    }
  };

  hasDropdown(a: NavModel, state: ISiteDetailsState) {
    switch (a.config.name) {
      case routeNames.sitedetailsTanks:
        return state.hasMultipleTanks;
      default:
        return false;
    }
  }

  isActive(a: NavModel, state: ISiteDetailsState) {
    if (isNone(a.config.name)) return false;
    if (routeIsActive(state.currentRoute, a.config.name)) return true;
    switch (a.config.name) {
      case routeNames.sitedetailsTanks:
        return state.showTanks;
    }
    return false;
  }

  activate({ id }: { id: string | undefined }) {
    if(isNone(id)) return;
    const siteId = ensureNumber(id)
    if(isNaN(siteId)) return;
    
    const visited = LocalSettings.getSettingParsed<number[]>('recently-viewed-sites', [])
    const newVisitedArray = Array.from(new Set<number>([siteId, ...visited])).slice(0, 30);
    LocalSettings.setSetting('recently-viewed-sites', JSON.stringify(newVisitedArray))

    this.siteService.setSiteDetailsVisited(id)
    this.attachMapState(this.mapState(siteId));
  }

  mapState = (siteId: number) => (state: rootState): ISiteDetailsState => {
    const accessRights = mapAccessRights(getSession());
    const siteAlarms = this.alarmService
      .getAlarmsBySiteId(siteId)
      .getEntityOrDefault(emptyArray);
    const activeAlarms = siteAlarms.filter(a => a.active);
    const tankFetcher = this.channelService
      .getSiteChannelsForSite(siteId)
      .map(filterOnlyTankChannels);
    const nativeControllersFetcher = this.controllerService
      .getControllersBySiteId(siteId)
      .map(controllers =>
        controllers.filter(controller => controller.siteId === siteId)
      );
    const tankListIsShown = showTanks(state);

    return {
      siteId,
      showNavigation: showNavigationBar(state),
      showTanks: tankListIsShown,
      submenuIsShown: state.sitedetails.submenuIsShown,
      controllers: nativeControllersFetcher.getEntityOrDefault(emptyArray),
      screenIsMobile: state.device.screenSize === 'mobile',
      currentRoute: state.router.currentRoute,
      accessRights,

      alarms: {
        active: activeAlarms
      },

      session: getSession(),
      hasMultipleTanks: tankFetcher
        .map(tanks => tanks.length > 1)
        .getEntityOrDefault(false),
      siteChannelIdsForTanks: tankFetcher
        .map(t => t.map(k => k.siteChannelId))
        .getEntityOrDefault(emptyArray)
    };
  };

  toggleShowTanks() {
    this.dispatch(toggleShowTanks());
  }

  @computedFrom('navigation', 'hasSubNavigation')
  get mainNavigation() {
    return this.hasSubNavigation
      ? this.navigation.slice(0, Math.floor(this.navigation.length / 2))
      : this.navigation;
  }

  @computedFrom('navigation', 'hasSubNavigation')
  get subNavigation() {
    return this.hasSubNavigation
      ? this.navigation.slice(Math.floor(this.navigation.length / 2))
      : undefined;
  }

  @computedFrom('router.navigation', 'state')
  get navigation() {
    const isMobile = this.state.screenIsMobile;
    const getSort = isMobile
      ? ({ settings }: NavModel) => settings.mobile
      : ({ settings }: NavModel) => settings.desktop;

    const sort = (r1: NavModel, r2: NavModel) => {
      const r1prop = getSort(r1);
      const r2prop = getSort(r2);
      if (r1prop > r2prop) return 1;
      if (r1prop === r2prop) return 0;
      return -1;
    };
    return this.router.navigation
      .filter(route => !isNone(getSort(route)))
      .sort(sort)
      .map(k => ({ ...k, t: getSort(k) }));
  }

  @computedFrom('navigation')
  get hasSubNavigation() {
    return this.navigation.length > 5;
  }

  @computedFrom('navigation')
  get navigationIsEvenNumber() {
    return this.navigation.length % 2 === 0;
  }

  tabItemClick(a: NavModel) {
    const currentRouteIsIndex = routeIsActive(
      this.state.currentRoute,
      routeNames.sitedetailsIndex
    );

    if (a.config.name === routeNames.sitedetailsIndex && currentRouteIsIndex) {
      return;
    }

    const shouldReplaceHistory =
      !currentRouteIsIndex || !this.state.screenIsMobile;
    if (
      a.config.name === routeNames.sitedetailsTanks &&
      !this.state.screenIsMobile
    ) {
      if (this.state.siteChannelIdsForTanks.length > 0) {
        this.router.navigate(
          `tanks/tankDetails/${this.state.siteChannelIdsForTanks[0]}`,
          { replace: shouldReplaceHistory }
        );
      } else {
        this.dispatch(toggleShowTanks());
      }
      return;
    }
    if (
      a.config.name === routeNames.sitedetailsControllers &&
      this.state.controllers.length <= 1
    )
      return this.state.controllers.length === 1
        ? this.router.navigate(
            `controllers/${this.state.controllers[0].controllerId}`,
            { replace: shouldReplaceHistory }
          )
        : this.router.navigateToRoute(
            routeNames.sitedetailsControllers,
            undefined,
            { replace: shouldReplaceHistory }
          );

    if (!this.state.screenIsMobile)
      return this.router.navigate(a.href, { replace: true });

    if (a.config.name === routeNames.sitedetailsIndex)
      this.router.navigateBack();
    else this.router.navigate(a.href, { replace: shouldReplaceHistory });
    return;
  }

  navigateToController(controller: IController) {
    const currentRouteIsIndex = routeIsActive(
      this.state.currentRoute,
      routeNames.sitedetailsIndex
    );
    this.router.navigateToRoute(
      routeNames.sitedetailsControllers,
      {
        controllerId: controller.controllerId
      },
      { replace: !currentRouteIsIndex || !this.state.screenIsMobile }
    );
  }

  toggleSubMenu(previousValue: boolean) {
    this.dispatch(toggleSubMenuSiteDetails(!previousValue));
  }
}
